<template>
  <div class="w-full pb-24">
    <label
      v-if="label"
      :for="id"
      class="flex items-center text-14 font-medium mb-8 text-blueGray-500 text-left"
    >
      {{ label }}

      <ElTooltip
        v-if="required"
        placement="top"
        effect="light"
      >
        <template #content>Required field!</template>
        <div class="cursor-pointer ml-4">*</div>
      </ElTooltip>

      <div
        v-if="$slots.infoText"
        class="flex ml-8"
      >
        <ElTooltip
          placement="top"
          effect="light"
        >
          <template #content>
            <div class="w-full max-w-256 text-center leading-tight">
              <slot name="infoText" />
            </div>
          </template>
          <i class="icon-info text-base text-blueGray-500 cursor-pointer" />
        </ElTooltip>
      </div>
    </label>

    <ElUpload
      :id="id"
      ref="elUpload"
      v-model:file-list="selectedFilesList"
      class="app-file-upload"
      :class="{'is-error': errorMessage}"
      drag
      accept="image/png, image/jpeg, image/jpg"
      action="undefined"
      :multiple="multiple"
      :auto-upload="false"
      :limit="limit"
      :show-file-list="false"
      :on-exceed="onExceed"
      :on-change="onChange"
      :on-remove="onChange"
    >
      <div class="flex w-full h-full flex-col items-center justify-center">
        <i
          v-if="!fileUrl"
          class="text-gray-400 text-24 mb-8"
          :class="{
            'icon-photo': selectedFilesList.length,
            'icon-cloud-upload': !selectedFilesList.length
          }"
        />

        <template v-if="selectedFilesList.length">
          <p
            v-for="(file, index) in selectedFilesList"
            :key="index"
            class="text-gray-600 font-bold text-18 leading-tight truncate"
          >
            {{ file.name }}
          </p>

          <p
            class="flex items-center text-blue-500 text-14
            leading-none mt-12 hover:text-blue-600 transition duration-200"
            @click.stop="onClear"
          >
            <i class="icon-cancel-circle text-14 mr-8 no-underline" />
            Clear
          </p>
        </template>
        <template v-else-if="fileUrl">
          <div
            class="w-full h-full flex flex-col justify-center items-center"
          >
            <AppImage
              class="w-full h-full max-h-152 flex flex-1 items-center justify-center"
              :src="fileUrl"
              image-class="max-h-full"
            />

            <p
              class="flex items-center text-blue-500 text-14
              leading-none mt-12 hover:text-blue-600 transition duration-200"
            >
              Click on image to update
            </p>
          </div>
        </template>
        <p
          v-else
          class="text-gray-600 font-medium text-14 truncate leading-tight"
        >
          Drop file here or <em class="text-blue-500">click to upload</em>
        </p>
      </div>

      <template #tip>
        <p class="text-gray-600 text-medium text-12">jpeg/jpg/png files with a size less than 24mb</p>
      </template>
    </ElUpload>

    <p
      v-if="errorMessage"
      class="w-full h-24 text-12 text-red-500 truncate leading-none mt-8 cursor-pointer"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { ElUpload, ElTooltip } from 'element-plus'

import { fileUploadService } from '@/services'
import AppImage from './AppImage.vue'

export default defineComponent({
  name: 'AppFileUpload',

  components: {
    ElUpload,
    ElTooltip,
    AppImage
  },

  props: {
    id: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    multiple: {
      type: Boolean,
      dafault: false
    },
    limit: {
      type: Number,
      default: 1
    },
    fileUrl: {
      type: [String, null],
      default: null
    }
  },

  setup (props) {
    const elUpload = ref()
    const errorMessage = ref(null)
    const selectedFilesList = ref([])

    const isFileSelected = computed(() => {
      return !!selectedFilesList.value.length
    })

    const submitUpload = async () => {
      try {
        console.log(selectedFilesList.value)
        if (!selectedFilesList.value?.length) {
          return null
        }

        const formData = new FormData()
        formData.append('image', selectedFilesList.value[0].raw)

        const { file } = await fileUploadService.uploadImage(formData)

        return file
      } catch (e) {
        console.log(e)
        errorMessage.value = 'Error during file uploading. Please, try another file!'

        return null
      }
    }

    const onExceed = () => {
      errorMessage.value = `Maximum upload files count is ${props.limit}`
    }
    const onChange = () => {
      errorMessage.value = null
    }
    const onClear = () => {
      elUpload.value.uploadFiles = []
    }

    return {
      elUpload,
      errorMessage,
      selectedFilesList,
      isFileSelected,
      submitUpload,
      onExceed,
      onChange,
      onClear
    }
  }
})
</script>
