<template>
  <div>
    <AppLoading
      v-if="loading"
      :class="loadingClass"
      color="text-blue-600"
    />

    <img
      v-show="!loading"
      :class="[imageClass]"
      :alt="alt || src"
      :src="src"
      @load="onLoadHandler"
    >
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

import AppLoading from './AppLoading.vue'

export default defineComponent({
  name: 'AppImage',

  components: { AppLoading },

  props: {
    src: {
      type: String,
      require: true
    },
    alt: {
      type: String,
      default: null
    },
    imageClass: {
      type: String,
      default: ''
    },
    loadingClass: {
      type: String,
      default: 'w-32 h-32'
    }
  },

  setup () {
    const loading = ref(true)

    const onLoadHandler = () => {
      loading.value = false
    }

    return {
      loading,
      onLoadHandler
    }
  }
})
</script>
